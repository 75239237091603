<template>
  <div class="create-widgetTemplate-page">
    <BackTitle confirm-click @click="goBack">
      Create widget template
    </BackTitle>
    <WidgetTemplateForm
      :value="widgetTemplate"
      submitButtonText="Add"
      :isSubmitting="isSubmitting"
      @submit="save"
    />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle.vue";
import WidgetTemplateForm from "@/components/widget/WidgetTemplateForm.vue";
import { NEW_WIDGET_TEMPLATE_MOCK } from "@/helpers/mocks";
import { mapActions } from "vuex";
import cloneDeep from "lodash.clonedeep";

export default {
  name: "CreateWidgetTemplatePage",
  components: { WidgetTemplateForm, BackTitle },
  data() {
    return {
      widgetTemplate: cloneDeep(NEW_WIDGET_TEMPLATE_MOCK),
      isSubmitting: false,
    };
  },
  methods: {
    ...mapActions({
      createWidgetTemplate: "widgetTemplates/createWidgetTemplate",
    }),
    async save(data) {
      try {
        this.isSubmitting = true;
        await this.createWidgetTemplate(data);
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
    goBack() {
      this.$router.push({
        name: "WidgetTemplates",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.create-widgetTemplate-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}
</style>
