import { render, staticRenderFns } from "./CreateWidgetTemplatePage.vue?vue&type=template&id=08d687a9&scoped=true&"
import script from "./CreateWidgetTemplatePage.vue?vue&type=script&lang=js&"
export * from "./CreateWidgetTemplatePage.vue?vue&type=script&lang=js&"
import style0 from "./CreateWidgetTemplatePage.vue?vue&type=style&index=0&id=08d687a9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08d687a9",
  null
  
)

export default component.exports